body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
  font-size: 1.5rem;
  font-weight: bold;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body{
  background-image: url(./background.jpg);
  background-size: cover;
  background-position: bottom;
}

main{
  padding: 2rem;
  background: #fff4;
  backdrop-filter: blur(8px);
  border-radius: 3rem;
  width: 24rem;
}

#root{
  display: grid;
  text-align: center;
  place-content: center;
  min-height: 100vh;
}

#connect-button,
#mint-button{
  border-radius: 1.5rem;
  padding: 0.75rem 1.5rem;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  background-color: #eb6123;
}

.input-control-button{
  width: 2rem;
  height: 2rem;
  margin: 1rem;

  outline: none;
  border: none;
  border-radius: 50%;

  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  background-color: #eb6123;

  cursor: pointer;
}
